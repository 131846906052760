@import '~assets/styles/utils/mixin';

.header {
    width: 100vw;
    height: 80px;
    border-bottom: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    box-sizing: border-box;
    transition: $transition;
    @each $theme, $map in $themes {
        .#{$theme} & {
            border-color: map-get($map, borderColor);
            background-color: map-get($map, white);
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        &__hamburger {
            width: 78px;
            height: 78px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__toggler {
            width: 26px;
            height: 18px;
            position: relative;
            transform: rotate(0deg);
            transition: 0.5s ease-in-out;
            cursor: pointer;

            &.focus-visible,
            &__open.focus-visible {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        box-shadow: 0 0 0 0.2rem (map-get($map, brandFocus));
                    }
                }
            }

            > span {
                display: block;
                position: absolute;
                height: 2px;
                width: 100%;
                border-radius: 9px;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: 0.25s ease-in-out;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background: map-get($map, brandPantonText);
                    }
                }
            }

            > span:nth-child(1) {
                top: 0;
            }

            > span:nth-child(2),
            > span:nth-child(3) {
                top: 8px;
            }

            > span:nth-child(4) {
                top: $fz-lg;
            }

            &__opened {
                > span:nth-child(1) {
                    top: 8px;
                    width: 0%;
                    left: 50%;
                }

                > span:nth-child(2) {
                    transform: rotate(45deg);
                }

                > span:nth-child(3) {
                    transform: rotate(-45deg);
                }

                > span:nth-child(4) {
                    top: 8px;
                    width: 0%;
                    left: 50%;
                }
            }
        }

        &__logo {
            > svg {
                height: 28px;
                width: auto;
                g:nth-child(2) {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            fill: map-get($map, brandPantonText);
                        }
                    }
                }
            }
        }

        &__user {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: auto;

            .popup__container {
                margin-right: $indent-lg;
            }

            .header__content__user-info {
                cursor: pointer;
            }

            > div:nth-child(3) {
                > button {
                    &:focus-visible {
                        box-shadow: none !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .header {
        &__content {
            &__logo {
                margin-left: auto;
            }

            &__user {
                display: none;
            }
        }
    }
}
