.please-select-row {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    > span {
        font-size: 14px;
        font-weight: 500;
    }
}
