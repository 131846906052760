@import '~assets/styles/utils/mixin';

.switch {
    min-width: 29px;
    height: 18px;
    display: flex;
    align-items: center;
    border-radius: 9px;
    cursor: pointer;

    &__thumb {
        width: 18px;
        height: 18px;
        padding: 2px;
        transition: $transition;

        &-dot {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: map-get($map, white);
                }
            }
        }
    }

    &--active {
        transition: $transition;
        @each $theme, $map in $themes {
            .#{$theme} & {
                background: map-get($map, brandPantonBlue);
            }
        }

        &:hover {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: lighten(map-get($map, brandPantonBlue), 10%);
                }
            }
        }

        .switch__thumb {
            transform: translateX(10px);
        }
    }

    &--inactive {
        transition: $transition;
        @each $theme, $map in $themes {
            .#{$theme} & {
                background: transparentize(map-get($map, black), 0.7);
            }
        }

        &:hover {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: transparentize(map-get($map, black), 0.77);
                }
            }
        }
    }

    &--active.switch--disabled {
        transition: $transition;
        cursor: not-allowed;
        @each $theme, $map in $themes {
            .#{$theme} & {
                background: transparentize(map-get($map, brandPantonBlue), 0.5);
            }
        }

        &:hover {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: lighten(transparentize(map-get($map, brandPantonBlue), 0.5), 10%);
                }
            }
        }
    }

    &.focus-visible {
        @each $theme, $map in $themes {
            .#{$theme} & {
                box-shadow: 0 0 0 0.2rem (map-get($map, brandFocus));
                z-index: 100;
            }
        }
    }
}
