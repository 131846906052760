@import '~assets/styles/utils/mixin';

.module-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $indent-md $indent-md $indent-md 0;
    border-bottom: 2px solid;
    transition: $transition;
    @each $theme, $map in $themes {
        .#{$theme} & {
            border-color: map-get($map, borderColor);
        }
    }

    & > *:nth-child(n):not(:last-child):not(:only-child) {
        margin-right: $indent-lg;
    }

    &__name {
        flex: 1 100%;
    }

    &__arrows {
        display: flex;

        & > *:nth-child(n):not(:last-child):not(:only-child) {
            margin-right: $indent-sm;
        }

        & > * {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $indent-sm;
            cursor: pointer;

            &.focus-visible {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        box-shadow: 0 0 0 0.2rem (map-get($map, brandFocus));
                        z-index: 100;
                    }
                }
            }
        }

        &-up {
            &.disabled {
                opacity: 0.2;
            }
        }

        &-down {
            transform: rotate(180deg);

            &.disabled {
                opacity: 0.2;
            }
        }
    }

    &.inactive {
        & > * {
            opacity: 0.2;
        }
    }
}
