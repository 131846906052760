@import '~assets/styles/utils/mixin';

.twod-identifier-issuing {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > *:nth-child(n):not(:only-child):not(:last-child) {
        margin-bottom: calc(#{$indent-md} * 2);
    }

    &__header {
    }

    &__body {
        flex: 1 100%;
        display: flex;

        & > *:nth-child(n):not(:only-child):not(:last-child) {
            margin-right: 35px;
        }

        &-download {
            width: 150px;

            & > *:nth-child(n):not(:only-child):not(:last-child) {
                margin-bottom: $indent-md;
            }

            .qr-image {
                width: 150px;
                height: 160px;
                border-radius: $border-rad_10;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background: map-get($map, brandBorderColor);
                    }
                }
            }

            .custom-button--custom {
                width: 100%;
            }
        }

        &-generate {
            flex: 1 100%;
            display: flex;
            flex-direction: column;

            & > *:nth-child(n):not(:only-child):not(:last-child) {
                margin-bottom: $indent-md;
            }

            .identifier-issuing {
                &__template {
                    display: flex;
                    align-items: center;

                    & > *:nth-child(n):not(:only-child):not(:last-child) {
                        margin-right: $indent-md;
                    }

                    .custom-input {
                        width: 258px;
                    }

                    &-save {
                        height: 36px;
                        display: flex;
                        align-items: center;
                        align-self: flex-end;
                        transition: $transition;
                        cursor: pointer;

                        & > *:nth-child(n):not(:only-child):not(:last-child) {
                            margin-right: $indent-sm;
                        }

                        & > *:nth-child(2),
                        svg {
                            transition: $transition;
                        }

                        svg {
                            width: 12px;
                            @each $theme, $map in $themes {
                                .#{$theme} & {
                                    fill: map-get($map, brandPantonText);
                                }
                            }
                        }

                        &:hover {
                            transform: scale(1.1);

                            & > *:nth-child(2),
                            svg {
                                @each $theme, $map in $themes {
                                    .#{$theme} & {
                                        color: map-get($map, brandPantonBlue) !important;
                                        fill: map-get($map, brandPantonBlue);
                                    }
                                }
                            }
                        }
                    }
                }

                &__textarea {
                    flex: 1 100%;
                    padding: $indent-sm $indent-md;
                    border-radius: 5px;
                    transition: $transition;
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            border: 1px solid map-get($map, brandInputBorder);
                        }
                    }

                    & > * {
                        width: 100%;
                        height: 100%;
                    }

                    &:hover {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                border-color: map-get($map, brandPantonBlue);
                            }
                        }
                    }

                    &:focus-within {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                border-color: map-get($map, brandPantonText);
                            }
                        }
                    }

                    &:hover,
                    &:focus-within {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                box-shadow: 0 3px 5px 2px map-get($map, brandBoxShadow);
                            }
                        }
                    }
                }

                &__buttons {
                    .custom-button--custom {
                        width: 162px;
                    }
                }
            }
        }
    }
}
