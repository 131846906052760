@import '~assets/styles/utils/mixin';

.events {
    display: flex;
    flex-direction: column;

    .event {
        min-height: 125px;
        display: flex;
        flex-direction: column;
        padding: $indent-md;
        margin: $indent-md $indent-md 0 $indent-md;
        border: 2px solid;
        border-radius: $border-rad_10;
        transition: $transition;
        overflow: hidden;
        @each $theme, $map in $themes {
            .#{$theme} & {
                border-color: map-get($map, borderColor);
                background: map-get($map, white);
                box-shadow: 0 10px 10px transparentize(map-get($map, black), 0.95);
            }
        }

        & > *:nth-child(n):not(:last-child):not(:only-child) {
            margin-bottom: $indent-md;
        }

        &__header {
            height: 100%;
            display: flex;
            overflow: hidden;

            & > *:nth-child(n):not(:last-child):not(:only-child) {
                margin-right: $indent-sm;
            }

            &-info {
                flex: 1 100%;
                overflow: hidden;

                & > *:nth-child(n):not(:last-child):not(:only-child) {
                    margin-bottom: $indent-sm;
                }

                & > * {
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                & > *:nth-child(2) {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            color: map-get($map, brandPantonSubText);
                        }
                    }
                }
            }

            &-button {
                min-width: 83px;
                display: flex;
                align-items: center;
                padding: $indent-sm;
                border-radius: 5px;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background: transparentize(map-get($map, brandPantonSubText), 0.8);
                    }
                }

                & > *:nth-child(n):not(:last-child):not(:only-child) {
                    margin-right: $indent-sm;
                }

                svg {
                    width: 16px;
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            fill: transparentize(map-get($map, brandPantonSubText), 0.2);
                        }
                    }
                }

                & > * {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            color: transparentize(map-get($map, brandPantonSubText), 0.2);
                        }
                    }
                }

                &.event-exit {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            background: transparentize(map-get($map, brandPantonRed), 0.85);
                        }
                    }

                    svg {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                fill: map-get($map, brandPantonRed);
                            }
                        }
                    }

                    & > * {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                color: map-get($map, brandPantonRed);
                            }
                        }
                    }
                }

                &.event-enter {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            background: transparentize(map-get($map, brandPantonGreen), 0.8);
                        }
                    }

                    svg {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                fill: map-get($map, brandPantonGreen);
                            }
                        }
                    }

                    & > * {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                color: map-get($map, brandPantonGreen);
                            }
                        }
                    }
                }
            }
        }

        &__body {
            flex: 1 100%;
            display: flex;

            & > *:nth-child(n):not(:last-child):not(:only-child) {
                margin-right: $indent-md;
            }

            &-photo {
                min-width: 46px;
                max-width: 46px;
                height: 46px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                padding: $indent-sm;
                transition: $transition;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background: transparentize(map-get($map, black), 0.95);
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 5px;
                    object-fit: cover;
                }

                svg {
                    width: 100%;
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            fill: transparentize(map-get($map, black), 0.8);
                        }
                    }
                }
            }

            &-info {
                flex: 1 100%;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                & > *:nth-child(n):not(:last-child):not(:only-child) {
                    margin-bottom: 4px;
                }

                & > *:not(.employee-error) > * {
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .employee-id {
                    & > * {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                color: map-get($map, brandPantonSubText);
                            }
                        }
                    }
                }

                .free-passage {
                    flex: 1 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    white-space: pre;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .employee-error {
                    flex: 1 100%;
                    display: flex;
                    align-items: center;

                    & > *:nth-child(n):not(:last-child):not(:only-child) {
                        margin-right: $indent-md;
                    }

                    & > * {
                        line-height: 18px;
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                color: map-get($map, brandPantonRed);
                                fill: map-get($map, brandPantonRed);
                            }
                        }
                    }

                    svg {
                        min-width: 30px;
                    }
                }
            }

            &__employee-surname {
                margin-bottom: 5px;
            }

            &__employee-name {
                margin-bottom: 5px;
            }

            &__employee-id {
                & > * {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            color: map-get($map, brandPantonSubText);
                        }
                    }
                }
            }
        }

        &__footer {
            padding-top: $indent-md;
            border-top: 2px solid;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    border-color: map-get($map, borderColor);
                }
            }
        }

        &:hover {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    box-shadow: 0 20px 20px transparentize(map-get($map, black), 0.95);
                }
            }
        }

        &.error {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: transparentize(map-get($map, brandPantonRed), 0.9);
                    border-color: map-get($map, brandPantonRed);
                }
            }

            .event__header {
                &-button {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            background: transparentize(map-get($map, brandPantonRed), 0.8);
                        }
                    }

                    svg {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                fill: map-get($map, brandPantonRed);
                            }
                        }
                    }

                    & > * {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                color: map-get($map, brandPantonRed);
                            }
                        }
                    }
                }
            }

            .event__body {
                &-photo {
                    display: none;
                }
            }

            .event__footer {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        border-color: transparentize(map-get($map, brandPantonRed), 0.5);
                    }
                }

                & > * {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            color: map-get($map, brandPantonRed);
                        }
                    }
                }
            }
        }

        &:last-child {
            margin: $indent-md;
        }
    }
}
