@import '~assets/styles/utils/mixin';

.access-points-type {
    height: 100%;
    display: flex;

    & > *:nth-child(n) {
        padding-top: calc(#{$indent-md} * 2);
    }

    &__description {
        width: 270px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & > * {
            width: 163px;
            line-height: 22px !important;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    color: transparentize(map-get($map, brandPantonText), 0.5) !important;
                }
            }

            &::before {
                content: '';
                position: relative;
                display: block;
                width: 35px;
                height: 2px;
                margin-bottom: $indent-md;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background: map-get($map, brandPantonBlue);
                    }
                }
            }

            &:nth-child(n):not(:last-child):not(:only-child) {
                margin-bottom: 64px;
            }
        }
    }

    &__selector {
        height: 100%;
        display: flex;
        flex-direction: column;

        &-title {
            margin-bottom: 28px;
        }

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &-item {
            width: 130px;
            height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: $indent-md;
            margin: 0 $indent-sm $indent-sm 0;
            border-radius: $border-rad_10;
            transition: $transition;
            cursor: pointer;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    border: 2px solid map-get($map, white);
                    background: map-get($map, white);
                    box-shadow: 0 10px 10px transparentize(map-get($map, black), 0.9);
                }
            }

            svg {
                width: 30px;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        fill: map-get($map, brandPantonText);
                    }
                }
            }

            & > *:first-child {
                margin-bottom: $indent-lg;
            }

            & > *:nth-child(2) {
                text-align: center;
            }

            &:hover {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        border-color: map-get($map, brandPantonBlue);
                    }
                }
            }

            &--active {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        border-color: map-get($map, brandPantonBlue);
                    }
                }

                & > *:nth-child(2),
                svg {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            fill: map-get($map, brandPantonBlue);
                            color: map-get($map, brandPantonBlue);
                        }
                    }
                }
            }

            &--disabled {
                cursor: not-allowed;
                
                &:hover {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            border-color: transparentize(map-get($map, brandBorderColor), 0.5);
                        }
                    }
                }

                & > *:nth-child(2),
                svg {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            fill: transparentize(map-get($map, brandPantonText), 0.5);
                            color: transparentize(map-get($map, brandPantonText), 0.5);
                        }
                    }
                }
            }

            &.focus-visible {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        box-shadow: 0 0 0 0.2rem map-get($map, brandFocus);
                    }
                }
            }
        }
    }
}
