@import '~assets/styles/utils/mixin';

.access-patterns {
    flex: 1 100%;
    display: flex;
    grid-gap: calc(#{$indent-md} * 2);

    .patterns-selector {
        flex: 1 50%;
        display: flex;
        flex-direction: column;

        &__title {
            margin-bottom: calc(#{$indent-md} * 2);
        }

        &__body {
            flex: 1 100%;
            overflow: hidden;

            .accordion {
                width: calc(100% - #{$indent-lg});
            }
        }
    }

    .access-zones {
        flex: 1 50%;
        display: flex;
        flex-direction: column;

        &__title {
            margin-bottom: calc(#{$indent-md} * 2);
        }

        &__body {
            flex: 1 100%;
            overflow: hidden;

            .zones__item:nth-child(n):not(:last-child):not(:only-child) {
                margin-bottom: $indent-lg;
            }
        }
    }
}
