@import '~assets/styles/utils/mixin';

.access-points-general {
    width: 100%;
    display: flex;
    flex-direction: column;

    & > *:nth-child(n):not(:last-child):not(:only-child) {
        margin-bottom: calc(#{$indent-md} * 2);
    }

    &__title {
    }

    &__body {
        flex: 1 100%;
        display: flex;
        flex-direction: column;
    
        & > *:nth-child(n):not(:last-child):not(:only-child) {
            margin-bottom: $indent-md;
        }
    
        .general-settings {
            &__header {
                display: flex;
    
                & > *:nth-child(n):not(:last-child):not(:only-child) {
                    margin-right: $indent-md;
                }
    
                & > *:nth-child(2) {
                    min-width: 182px;
                    max-width: 182px;
                }
            }
    
            &__body {
                flex: 1 100%;
                max-height: 130px;
                display: flex;
                flex-direction: column;
            }

            &__footer {
                display: flex;

                & > *:nth-child(n):not(:last-child):not(:only-child) {
                    margin-right: $indent-md;
                }
            }
        }
    }
}
