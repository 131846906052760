@import '~assets/styles/utils/mixin';

.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: #dfe7ee80;
    @each $theme, $map in $themes {
        .#{$theme} & {
            color: map-get($map, brandPantonText);
            background-color: map-get($map, white);
        }
    }

    .login-left {
        width: 50vw;
        height: 100vh;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        animation: leftAppiring 1s forwards;
        @each $theme, $map in $themes {
            .#{$theme} & {
                background-color: map-get($map, white);
            }
        }

        &.animation {
            animation: leftLoginPartAnim 2s forwards;

            .login-left__content {
                animation: leftContentAnim 2s forwards;
            }
        }

        &__content {
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            margin: auto;

            & > *:nth-child(n) {
                margin-bottom: $indent-md;
            }

            & > *:last-child,
            & > *:only-child,
            & > *:nth-last-child(2) {
                margin-bottom: 0;
            }
        }

        &__title {
            width: 100%;
        }

        &__form {
            & > *:nth-child(n) {
                margin-bottom: $indent-md;
            }

            & > *:last-child {
                margin: 0;
            }
        }

        &__message {
            position: absolute;
            top: calc(100% + #{$indent-lg});
            left: 0;

            &-warning > * {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        color: map-get($map, brandPantonYellow);
                    }
                }
            }

            &-error {
                display: flex;
                align-items: center;

                & > * {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            color: map-get($map, brandPantonRed);
                            fill: map-get($map, brandPantonRed);
                        }
                    }
                }

                svg {
                    width: 36px;
                    height: 36px;
                    margin-right: $indent-md;
                }
            }
        }

        .custom-button {
            width: 100%;
            border-radius: 5px;

            .buttons__label > * {
                text-align: left;
            }
        }
    }

    .login-right {
        width: 50vw;
        height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
        background: #fff;
        animation: rightAppiring 1s forwards;
        z-index: 1;

        &.animation {
            .login-right__content > * {
                animation: rightContentAnim 2s forwards;
            }
        }

        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            // background-color: #dfe7ee80;
            background: radial-gradient(50% 50% at 50% 50%, rgba(167, 167, 167, 0) 0%, #b8b8b8 100%);
        }

        &__logo {
            margin-top: 160px;
            width: 300px;
            z-index: 2;
            svg {
                g {
                    path:first-child {
                        fill: $brandPantonText;
                    }
                }
            }
        }

        &__title {
            margin-top: 10px;
        }

        &__body {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 1;

            & > img {
                width: 100%;
            }
        }
    }

    @keyframes leftContentAnim {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes rightContentAnim {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes leftLoginPartAnim {
        0% {
            width: 50vw;
        }
        50% {
            width: 50vw;
        }
        100% {
            width: 100vw;
        }
    }

    @keyframes leftAppiring {
        0% {
            opacity: 0;
            transform: scale(0.8);
        }
        50% {
            opacity: 1;
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes rightAppiring {
        0% {
            opacity: 0;
            transform: translateX(-50vw);
        }
        25% {
            opacity: 0;
            transform: translateX(-50vw);
        }
        75% {
            opacity: 1;
            transform: translateX(0);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
