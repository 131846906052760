@import './variables';

@mixin responsive($breakpoint) {
    @if $breakpoint == notebook {
        @media (max-width: 1366px) {
            @content;
        }
    }

    @if $breakpoint == bigDesktop {
        @media (min-width: 1921px) {
            @content;
        }
    }
}

@mixin _page-position {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

@mixin _font-family {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif;
}

@mixin _p--sm {
    @include _font-family;
    font-size: 12px;
    line-height: 12px;
    @each $theme, $map in $themes {
        .#{$theme} & {
            color: map-get($map, brandPantonText);
        }
    }
}

@mixin _p--md {
    @include _font-family;
    font-size: 14px;
    line-height: 14px;
    @each $theme, $map in $themes {
        .#{$theme} & {
            color: map-get($map, brandPantonText);
        }
    }
}

@mixin _p--lg {
    @include _font-family;
    font-size: 16px;
    line-height: 16px;
    @each $theme, $map in $themes {
        .#{$theme} & {
            color: map-get($map, brandPantonText);
        }
    }
}

@mixin ellipsis-cut-string {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
}
