@import '~assets/styles/utils/mixin';

.checkbox {
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    &__input {
        position: absolute;
        appearance: none;
    }

    &__thumb {
        min-width: 20px;
        min-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $indent-sm;
        border-radius: 4px;
        transition: $transition;
        @each $theme, $map in $themes {
            .#{$theme} & {
                background-color: map-get($map, white);
                border: 2px solid map-get($map, borderColor);
            }
        }
    }

    &__label > * {
        line-height: 20px !important;
    }

    /* Hover */
    &:hover {
        .checkbox__input:not(:disabled):checked + .checkbox__thumb {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: lighten(map-get($map, brandPantonBlue), 10%);
                    border-color: lighten(map-get($map, brandPantonBlue), 10%);
                }
            }
        }

        .checkbox__thumb {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    border-color: map-get($map, brandPantonBlue);
                }
            }
        }
    }

    /* Checked */
    &__input:checked {
        & + .checkbox__thumb {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: map-get($map, brandPantonBlue);
                    border-color: map-get($map, brandPantonBlue);
                }
            }

            svg {
                width: 10px;
                fill: white;
            }
        }
    }

    /* Focus */
    .focus-visible + .checkbox__thumb {
        @each $theme, $map in $themes {
            .#{$theme} & {
                box-shadow: 0 0 0 0.2rem map-get($map, brandFocus);
            }
        }
    }

    /* Disabled */
    &__input:disabled {
        & + .checkbox__thumb + .checkbox__label > * {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    color: transparentize(map-get($map, brandPantonText), 0.5) !important;
                }
            }
        }

        &:checked + .checkbox__thumb {
            border: none;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: transparentize(map-get($map, brandPantonBlue), 0.5);
                }
            }
        }

        &:not(:checked) + .checkbox__thumb {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: map-get($map, brandPantonFonts);
                    border-color: map-get($map, brandBorderColor);
                }
            }

            svg {
                width: 10px;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        fill: map-get($map, brandBorderColor);
                    }
                }
            }
        }
    }
}
