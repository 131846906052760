@import '~assets/styles/utils/mixin';

.settings__about__program {
    .about__programm__wrapper {
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: flex-start;
        width: 100%;

        .about__programm {
            &__header {
                display: flex;
                align-items: center;
                min-height: 165px;
                border-bottom: 1px solid $borderColor;

                & > svg {
                    width: 100%;
                    margin-left: 70px;
                    g:nth-child(2) {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                fill: map-get($map, brandPantonText);
                            }
                        }
                    }
                }

                .versions {
                    display: flex;
                    margin-left: 290px;

                    &__version {
                        position: relative;
                        margin-right: 50px;
                        p {
                            margin: 19px 0 7px;
                            color: $brandPantonSubText;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 35px;
                            height: 2px;
                            background: $brandPantonBlue;
                        }
                    }
                }
            }

            &__body {
                display: flex;
                width: 100%;
                // height: 100%;
                overflow: hidden;

                .software {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex: 0 1 50%;
                    padding: 35px 70px 0;
                    border-right: 1px solid $borderColor;

                    &__ims {
                        position: relative;
                        width: 450px;
                        height: 140px;
                        border-radius: 10px;
                        line-height: 22px;
                        text-decoration-line: underline;
                        padding: 38px 90px 50px 150px;
                        border: 2px solid $borderColor;
                        cursor: pointer;

                        &.selected {
                            border: 2px solid $brandPantonBlue;
                        }

                        svg {
                            position: absolute;
                            top: 25px;
                            left: 43px;
                            width: 60px;

                            & > g > path {
                                fill: $brandPantonBlue;
                            }
                        }
                    }

                    &__header {
                        color: $brandPantonSubText;
                        margin-top: 30px;
                    }

                    &__iconblocks {
                        display: flex;
                        flex-wrap: wrap;
                        width: 465px;

                        .iconblock {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 14px 14px 0 0;
                            width: 140px;
                            height: 140px;
                            border: 2px solid $borderColor;
                            border-radius: 10px;
                            cursor: pointer;

                            &.selected {
                                // background: $brandPantonBlue;

                                // & svg path {
                                //     fill: white;
                                // }
                                border: 2px solid $brandPantonBlue;
                            }

                            & svg path {
                                transition: all 0.2s ease;
                            }

                            &.lodash {
                                svg {
                                    width: 50px;
                                }
                            }

                            &.jquery {
                                svg {
                                    width: 90px;
                                }
                            }

                            &.eslint {
                                svg {
                                    width: 90px;
                                }
                            }

                            &.spring {
                                svg {
                                    width: 90px;
                                }
                            }

                            // HOVER STATES

                            &:hover.react {
                                svg > path {
                                    fill: #00d8ff;
                                }
                            }

                            &:hover.lodash {
                                svg > path:nth-child(2) {
                                    fill: #3492ff;
                                }
                            }

                            &:hover.typescript {
                                svg > path:nth-child(1) {
                                    fill: #007acc;
                                }
                            }

                            &:hover.jquery {
                                svg > g {
                                    fill: #0868ac;
                                }
                            }

                            &:hover.eslint {
                                svg > path:nth-child(1) {
                                    fill: #8080f2;
                                }

                                svg > path:nth-child(2) {
                                    fill: #4b32c3;
                                }
                            }

                            &:hover.spring {
                                svg > path {
                                    fill: #5fb832;
                                }
                            }
                        }
                    }

                    &__rest {
                        display: flex;
                        flex-direction: column;
                        width: 450px;
                        margin-top: 14px;
                        margin-bottom: 35px;
                        padding: 30px 100px 30px 21px;
                        border: 2px solid $borderColor;
                        border-radius: 10px;

                        h3 {
                            color: $brandPantonSubText;
                            margin-bottom: 21px;
                        }

                        .companys__list {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            grid-template-rows: 1fr 1fr;

                            button {
                                margin-right: 21px;
                                margin-bottom: 14px;
                                color: #191e22;
                                text-decoration-line: underline;
                                justify-self: flex-start;
                                white-space: nowrap;
                                cursor: pointer;

                                &:hover {
                                    color: $brandPantonBlue;
                                }
                            }
                        }
                    }
                }

                .license {
                    flex: 0 1 50%;

                    .test {
                        padding: 35px 70px;
                    }

                    h1 {
                        max-width: 400px;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 21px;
                        margin-bottom: 21px;
                    }

                    h2 {
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 14px;
                        color: $brandPantonSubText;
                    }

                    p {
                        margin-bottom: 21px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
