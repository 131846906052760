.filter-saved {
    display: flex;
    margin: 5px 20px;

    &__card {
        width: 100%;
        height: 125px;
        background-color: #f2f2f2;
        border-radius: 5px;
    }

    &__content {
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        margin: 15px;
    }

    &__header {
        font-size: 12px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 14px;
    }

    &__controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .filter-saved-controls__dropdown {
            width: 180px;
        }
    }

    &__save {
        display: flex;
        flex-direction: row;
        margin-top: auto;
        cursor: pointer;

        svg {
            width: 14px;
            margin-right: 5px;
        }
    }
}
