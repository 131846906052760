@import '~assets/styles/utils/mixin';

.quick-issuing-faceid {
    display: flex;

    & > *:nth-child(n):not(:only-child):not(:last-child) {
        margin-right: 35px;
    }

    &__left {
        & > *:nth-child(n):not(:only-child):not(:last-child) {
            margin-bottom: calc(#{$indent-md} * 2);
        }

        .quick-issuing-faceid__header {
        }

        .quick-issuing-faceid__body {
            display: flex;

            & > *:nth-child(n):not(:only-child):not(:last-child) {
                margin-right: 35px;
            }

            &-photo {
                width: 110px;
                height: 148px;
                border-radius: $border-rad_10;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background: map-get($map, brandBorderColor);
                    }
                }
            }

            &-buttons {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                & > *:nth-child(n):not(:only-child):not(:last-child) {
                    margin-bottom: $indent-md;
                }

                & > * {
                    width: 162px;
                }
            }
        }

        .quick-issuing-faceid__footer {
            &-descr {
                width: 168px;

                & > * {
                    line-height: 22px !important;
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            color: map-get($map, brandBorderColor);
                        }
                    }

                    &.error {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                color: map-get($map, brandPantonRed);
                            }
                        }
                    }

                    &.succes {
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                color: map-get($map, brandPantonGreen);
                            }
                        }
                    }
                }

                &::before {
                    content: '';
                    position: relative;
                    display: block;
                    width: 35px;
                    height: 2px;
                    margin-bottom: $indent-md;
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            background: map-get($map, brandPantonBlue);
                        }
                    }
                }
            }
        }
    }

    &__right {
        margin-top: 42px;

        .limits {
            flex-direction: column;

            & > *:nth-child(n):not(:only-child):not(:last-child) {
                margin-bottom: calc(#{$indent-md} * 2 * 2);
            }
        }
    }
}
