@import '~assets/styles/utils/mixin';

.radio {
    display: flex;
    align-items: center;
    cursor: pointer;

    input {
        position: absolute;
        appearance: none;
    }

    &__thumb {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $indent-sm;
        border-radius: 50%;
        transition: $transition;
        @each $theme, $map in $themes {
            .#{$theme} & {
                background-color: map-get($map, white);
                border: 2px solid map-get($map, borderColor);
            }
        }

        &-filler {
            width: 0;
            height: 0;
            border-radius: 50%;
            transition: $transition;
        }
    }

    /* Hover */
    &:hover {
        .radio__input {
            &:checked:not(:disabled) + .radio__thumb {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        border-color: lighten(map-get($map, brandPantonBlue), 10%);
                    }
                }

                .radio__thumb-filler {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            background: lighten(map-get($map, brandPantonBlue), 10%);
                        }
                    }
                }
            }
        }

        .radio__thumb {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    border-color: map-get($map, brandPantonBlue);
                }
            }
        }
    }

    /* Checked */
    &__input:checked + .radio__thumb {
        @each $theme, $map in $themes {
            .#{$theme} & {
                border-color: map-get($map, brandPantonBlue);
            }
        }

        .radio__thumb-filler {
            width: 100%;
            height: 100%;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background-color: map-get($map, brandPantonBlue);
                    border: 3px solid map-get($map, white);
                }
            }
        }
    }

    /* Focus */
    .focus-visible + .radio__thumb {
        @each $theme, $map in $themes {
            .#{$theme} & {
                box-shadow: 0 0 0 0.2rem map-get($map, brandFocus);
            }
        }
    }

    /* Disabled */
    &__input:disabled {
        &:not(:checked) + .radio__thumb {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: map-get($map, brandPantonFonts);
                    border-color: map-get($map, brandBorderColor);
                }
            }

            & + .radio__label > * {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        color: map-get($map, brandPantonSubText) !important;
                    }
                }
            }

            .radio__thumb-filler {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background: map-get($map, brandBorderColor);
                    }
                }
            }
        }

        &:checked + .radio__thumb {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: transparentize(map-get($map, brandPantonBlue), 0.5);
                    border-color: transparentize(map-get($map, brandPantonBlue), 0.5);
                }
            }

            .radio__thumb-filler {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background: transparentize(map-get($map, brandPantonBlue), 0.5);
                    }
                }
            }
        }
    }
}
