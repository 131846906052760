@import '~assets/styles/utils/mixin';

.copyright {
    min-height: 50px;
    max-height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    padding: $indent_md;
    transition: $transition;
    @each $theme, $map in $themes {
        .#{$theme} & {
            background: map-get($map, borderColor);
        }
    }

    &:before {
        width: 100%;
        height: $indent-lg;
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        pointer-events: none;
        transition: $transition;
        @each $theme, $map in $themes {
            .#{$theme} & {
                background: linear-gradient(0deg, map-get($map, white) 0%, transparentize(map-get($map, white), 1) 100%);
            }
        }
    }

    & > *:first-child {
        margin-right: $indent_sm;
    }

    & p {
        text-overflow: clip;
        white-space: nowrap;
        overflow: hidden;
    }
}

.hidden {
    .copyright {
        padding: 0;
        margin: 0;

        &__lable {
            width: 100%;
            height: 50px;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 14px;
            cursor: pointer;
            transition: $transition;
            z-index: 1;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    color: map-get($map, brandPantonText);
                    background: map-get($map, borderColor);
                }
            }
        }

        &__hide {
            width: 180px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            top: 0;
            left: -180px;
            padding-right: $indent_md;
            opacity: 0;
            transition: $transition;
            z-index: 0;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: map-get($map, borderColor);
                }
            }

            & > *:first-child {
                margin-bottom: $indent_sm;
            }
        }

        &:hover {
            .copyright__lable {
                z-index: 3;
            }

            .copyright__hide {
                left: 100%;
                opacity: 1;
                z-index: 2;
            }
        }

        & > *:first-child {
            margin-right: 0;
        }
    }
}
