@import '~assets/styles/utils/mixin';

.month__calendar__wrapper {
    height: 100%;
    overflow: hidden;

    .statistics {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        background: $brandBackground;
        border-radius: 10px;
        margin: 5px 0 10px -4px;
        padding: 10px 0;
        margin: 0 21px 21px 17px;
        @each $theme, $map in $themes {
            .#{$theme} & {
                background: map-get($map, brandBackground);
            }
        }

        .stat__spacer {
            position: absolute;
            top: 50%;
            left: 188px;
            transform: translateY(-50%);
            height: 45px;
            width: 1px;
            background: #bdbdbd;
        }

        .column {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 10px;
            margin-left: 145px;
        }

        .column:first-child {
            margin-left: 30px;
        }

        .stat__text {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: $brandSideColor;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    color: map-get($map, brandSideColor);
                }
            }
        }

        .stat__text:not(:last-child) {
            margin-bottom: 7px;
        }
    }

    .month__calendar__body {
        display: grid;
        grid-template-rows: 50px 50px 220px;
        grid-template-columns: 50px 355px;
        padding: 0 $indent-lg;
        margin-left: -20px;
        user-select: none;

        .calendar {
            &__weekdays {
                position: relative;
                grid-column: 1 / 3;
                display: flex;
                padding-left: 40px;

                &__day {
                    @include _p--sm;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    width: 50px;
                    height: 40px;
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            color: map-get($map, brandPantonSubText);
                        }
                    }

                    &.weekend {
                        color: #ffa6a6;
                    }
                }

                .weekdays__border {
                    position: absolute;
                    top: 42px;
                    left: 51px;
                    display: block;
                    background-color: $brandBorderColor;
                    height: 1px;
                    width: 326px;
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            background-color: map-get($map, brandBorderColor);
                        }
                    }
                }
            }

            &__spacer {
                display: block;
                width: 50px;
                height: 50px;
            }

            &__weeknumbers {
                @include _p--sm;
                grid-row: 2 / 4;
                display: flex;
                flex-direction: column;
                width: 40px;
                margin-top: 8px;

                &__number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    width: 40px;
                    height: 50px;
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            color: map-get($map, brandPantonSubText);
                        }
                    }
                }
            }

            &__body {
                grid-column: 2 /3;
                grid-row: 2 / 3;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                width: 355px;
                margin: 9px 0 0 -10px;

                & > .day {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    width: 50px;
                    height: 50px;
                    border-radius: 10px;
                    color: white;
                    transition: $transition;

                    .day__hours {
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: 15px;
                        z-index: 3;
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                color: map-get($map, brandTimeTableDay);
                            }
                        }
                    }

                    .day__number {
                        @include _p--md;
                        font-weight: 500;
                        margin-top: 18px;
                        z-index: 3;

                        &.weekend {
                            color: #ff5757;
                        }

                        &.selected {
                            color: $white;
                        }
                    }

                    &.selected {
                        .day__number {
                            color: white;
                        }
                    }

                    &.selected:after {
                        content: '';
                        position: absolute;
                        top: 11px;
                        left: -3px;
                        width: 57px;
                        height: 25px;
                        border-radius: 5px;
                        background: $brandPantonBlue;
                        z-index: 1;
                        box-shadow: 0px 10px 10px rgba(84, 173, 255, 0.25);
                    }

                    &.past {
                        opacity: 0.2;
                    }

                    &.weekend {
                        color: $brandTimeTableWeekend;
                    }

                    &:hover {
                        transform: scale(1.2);
                        background-color: #fff;
                        z-index: 100;
                        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
                        background: $brandBackground;
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                background: map-get($map, brandBackground);
                            }
                        }
                    }

                    &:hover.selected {
                        color: $white;
                    }
                }
            }
        }
    }

    .date__descriptions {
        padding: 21px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        margin: 30px 21px;
    }
}
