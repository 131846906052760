.reports__workingtime {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__placeholder {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
