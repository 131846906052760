@import '~assets/styles/utils/mixin';

.image-loader {
    &--btn {
        min-height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: $indent-sm;
        border-radius: $border-rad_10;
        transition: $transition;
        background-color: white;
        cursor: pointer;
        @each $theme, $map in $themes {
            .#{$theme} & {
                border: 2px solid map-get($map, brandPantonBlue);
            }
        }

        .image-loader__input {
            position: absolute;
            visibility: hidden;
        }

        .image-loader__label {
            & > * {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        color: map-get($map, brandPantonBlue);
                    }
                }
            }
        }

        &:hover {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    box-shadow: 0 5px 4px 0 rgba(map-get($map, brandPantonBlue), 0.35);
                }
            }
        }
    }
}
