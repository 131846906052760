@import '~assets/styles/utils/mixin';

.table-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: $indent-sm 0;

    &__buttons {
        display: flex;
        margin-right: $indent-lg;

        .custom-button {
            svg {
                padding: 4px;
            }
        }
    }

    &__label {
        display: flex;
    }
}
