@import '~assets/styles/utils/mixin';

.accordion-modules {
    &:not(:first-child) {
        @each $theme, $map in $themes {
            .#{$theme} & {
                border-top: 2px solid map-get($map, borderColor);
            }
        }
    }

    &__header {
        height: 50px;
        display: flex;
        align-items: center;
        user-select: none;

        & > * {
            margin-right: $indent-lg;
        }

        &-expender {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                transition: all 0.2s ease-in-out;
                transform: rotate(180deg);
            }
        }

        &-label {
            flex: 1 100%;
        }

        &-direction {
            display: flex;
            margin-right: 35px;

            & > *:nth-child(n):not(:last-child):not(:only-child) {
                margin-right: $indent-sm;
            }

            & > * {
                width: 70px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
                transition: $transition;
                cursor: pointer;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background: map-get($map, borderColor);
                        border: 1px solid map-get($map, white);
                    }
                }

                &:hover {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            border-color: map-get($map, brandPantonBlue);
                        }
                    }
                }

                &.active {
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            background: map-get($map, brandPantonBlue);
                        }
                    }

                    & > * {
                        color: white !important;
                    }
                }
            }
        }

        &.opened {
            height: 52px;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    border-bottom: 2px solid map-get($map, borderColor);
                }
            }

            .accordion-modules__header-expender {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
    }

    &__body {
        transition: all 0.2s ease-in-out;
        max-height: 0;

        margin: 0;
        overflow: hidden;

        &.opened {
            margin: $indent-lg 0;
            max-height: 1000px;
        }

        & > * {
            line-height: 20px !important;
        }
    }

    &:last-child,
    &:only-child {
        @each $theme, $map in $themes {
            .#{$theme} & {
                border-bottom: 2px solid map-get($map, borderColor);
            }
        }
    }
}
