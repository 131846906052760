.cdx-dropdown {
    width: 100%;
    height: 35px;
    background: white;
    display: flex;
    flex-direction: column;
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    cursor: pointer;
    user-select: none;
    position: relative;

    .cdx-dropdown__head {
        height: 100%;
        min-height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 15px;
        border: 1px solid #dfe1e6;
        border-radius: 5px;
        transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

        .dropdown-head {
            &__input {
                width: 100%;
                height: 100%;
            }

            &__cross {
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.15s ease-in-out;
            }
        }

        & > svg {
            transition: all 0.2s ease-in-out;
            margin-left: 10px;
        }
    }

    .cdx-dropdown__list {
        width: 100%;
        max-height: calc(35px * 8);
        display: none;
        margin-top: 35px;
        position: fixed;
        flex-direction: column;
        border: 1px solid #1d68d9;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 0;
        overflow: auto;
        background-color: white;
        z-index: 99999999999;
        transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

        .dropdown-list {
            &__item {
                display: flex;
                height: 35px;
                min-height: 35px;
                padding: 0 15px;
                align-items: center;
                background-color: white;
                transition: background-color 0.3s ease-in-out;

                &:hover {
                    background-color: #f2f2f2;
                }

                &:last-child {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }
    }

    &:hover,
    &.focused {
        .cdx-dropdown__head {
            border-color: #1d68d9;
            box-shadow: 0 3px 5px 2px #0000001a;
        }
    }

    &.opened {
        .cdx-dropdown__head {
            border-color: #1d68d9;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 1px solid #f2f2f2;

            svg {
                &:nth-child(3) {
                    transform: rotate(-180deg);
                }
            }
        }

        .cdx-dropdown__list {
            border-top: 1px solid #f2f2f2;
            display: flex;
        }
    }
}
