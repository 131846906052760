@import '~assets/styles/utils/mixin';

.rfid-identifier-issuing {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > *:nth-child(n):not(:only-child):not(:last-child) {
        margin-bottom: calc(#{$indent-md} * 2);
    }

    &__hardware {
        display: flex;
        flex-wrap: wrap;
        grid-gap: $indent-sm;

        & > * {
            min-width: 220px;
        }
    }

    &__option {
        flex: 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }
}
