@import '~assets/styles/utils/mixin';

.popup {
    &__container {
        position: relative;
        box-sizing: border-box !important;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 150%;
        left: 50%;
        transform: translateX(-50%);
        padding: 16px;
        width: 100%;
        animation-name: popup;
        animation-duration: 0.1s;
        z-index: 103;
        transition: $transition;
        @each $theme, $map in $themes {
            .#{$theme} & {
                color: map-get($map, brandPantonText);
                background-color: map-get($map, white);
                box-shadow: 0 2px 4px map-get($map, brandBoxShadow);
            }
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .custom-button {
            width: 100%;
            border-radius: 5px;
            border: none !important;

            &:hover {
                box-shadow: none !important;
                z-index: 1;
            }

            svg {
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        fill: map-get($map, brandPantonText);
                    }
                }
            }

            .buttons__label > * {
                font-weight: normal;
                text-align: left;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        color: map-get($map, brandPantonText);
                    }
                }
            }
        }

        & > *:not(:first-child) {
            margin-top: $indent-sm;
        }
    }
}

.closePopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 102;
}

@keyframes popup {
    from {
        top: 120%;
        opacity: 0;
    }

    to {
        top: 150%;
        opacity: 1;
    }
}
