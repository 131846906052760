@import '~assets/styles/utils/mixin';

.access-point-control {
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 580px;
    display: flex;
    flex-direction: column;

    &__general {
        display: flex;
        margin-top: $indent-lg;

        & > *:nth-child(n):not(:only-child):not(:last-child) {
            margin-right: $indent-md;
        }

        .access-point__description {
            flex: 1 100%;
            display: flex;
            overflow: hidden;

            & > *:nth-child(n):not(:only-child):not(:last-child) {
                margin-right: $indent-md;
            }

            &-left {
                flex: 1 60%;
                overflow: hidden;
            }

            &-right {
                flex: 1 40%;
                overflow: hidden;

                .access-point__description-elem {
                    & > *:first-child {
                        min-width: 90px;
                    }
                }
            }

            .access-point__description-elem {
                display: flex;
                align-items: center;

                &:nth-child(n):not(:only-child):not(:last-child) {
                    margin-bottom: $indent-lg;
                }

                &-icon {
                    min-width: 36px;
                    min-height: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    margin-right: $indent-md;
                    border-radius: $border-rad_10;
                    transition: $transition;
                    @each $theme, $map in $themes {
                        .#{$theme} & {
                            background: map-get($map, brandPantonText);
                        }
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        transition: $transition;
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                fill: map-get($map, white);
                            }
                        }
                    }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: calc(100% - 6px);
                        left: calc(100% - 9px);
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 1px solid;
                        transition: $transition;
                        @each $theme, $map in $themes {
                            .#{$theme} & {
                                background: lighten(map-get($map, black), 85%);
                                border-color: map-get($map, white);
                            }
                        }
                    }

                    &.active {
                        &::after {
                            @each $theme, $map in $themes {
                                .#{$theme} & {
                                    background: map-get($map, brandPantonGreen);
                                }
                            }
                        }
                    }

                    &.inactive {
                        &::after {
                            @each $theme, $map in $themes {
                                .#{$theme} & {
                                    background: map-get($map, brandPantonRed);
                                }
                            }
                        }
                    }
                }

                & > *:nth-child(n):not(:only-child).p--md--bold {
                    @include ellipsis-cut-string;
                }

                & > *:nth-child(n):only-child {
                    max-height: 50px;
                    text-overflow: ellipsis;
                    display: -moz-box;
                    display: -webkit-box;
                    box-orient: vertical;
                    -moz-box-orient: vertical;
                    -webkit-box-orient: vertical;
                    line-clamp: 3;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                }
            }
        }

        .access-point__button {
        }
    }

    .tabbar {
        flex: 1 100%;
    }
}
