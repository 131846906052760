@import '~assets/styles/utils/mixin.scss';

.usersandroles__edit {
    // height: 250px;
    width: 670px;
    padding: 21px 0;

    .userdata {
        display: flex;
        border-bottom: 2px solid $borderColor;
        padding-bottom: 35px;

        .edit__photo {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            margin: 0 13px 4px 0;
            border-radius: 50%;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background: map-get($map, brandPantonFonts);
                }
            }

            .user-photo {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: $indent-md;

                svg {
                    height: 100%;
                }
            }

            &__button {
                position: absolute;
                bottom: -3px;
                right: 7px;
                width: 34px;
                height: 34px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
                transition: all 0.1s ease-in-out;
                cursor: pointer;
                z-index: 10;

                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background-color: map-get($map, white);
                    }
                }

                &:active {
                    box-shadow: unset;
                }

                & > svg {
                    width: 14px;
                    height: 14px;
                    fill: $white;
                }

                &:active {
                    background-color: $brandPantonBlue;
                    & > svg {
                        fill: $white !important;
                    }
                }
            }

            &__dialogue {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: all 0.2s;
                background-color: rgba(5, 0, 4, 0.6);
                border-radius: 50%;

                &.open {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    opacity: 1;
                }

                & > button {
                    cursor: pointer;
                    margin-bottom: $indent-sm;
                    color: white;
                }
            }
        }

        &__info {
            display: flex;
            width: 100%;
        }

        &__login {
            display: flex;
            width: 100%;

            .login__label {
                margin: 7px 35px 0 0;
            }
        }
    }

    .password {
        padding-top: 30px;

        div[class^='password'] {
            display: flex;
            margin-bottom: 5px;

            span {
                white-space: nowrap;
                margin-top: 11px;
                color: $brandPantonText;
            }
            .custom-input__input-wrapper {
                input::placeholder {
                    color: $brandPantonSubText;
                }
            }
        }

        .current__label {
            margin-right: 56px;
        }

        .new__label {
            margin-right: 68px;
        }

        .confirmation__label {
            margin-right: 7px;
        }
    }
}
