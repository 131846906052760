@import '~assets/styles/utils/mixin';

.header__user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .user-info {
        &.focus-visible {
            @each $theme, $map in $themes {
                .#{$theme} & {
                    box-shadow: 0 0 0 0.2rem map-get($map, brandFocus);
                }
            }
        }

        &__separator {
            height: 30px;
            width: 1px;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background-color: map-get($map, borderColor);
                }
            }
        }

        &__name {
            margin-left: $indent-lg;
            margin-right: $indent-lg;
            font-size: $fz-md;
        }

        &__avatar {
            width: 40px;
            height: 40px;
            position: relative;
            border-radius: 50%;
            @each $theme, $map in $themes {
                .#{$theme} & {
                    background-color: map-get($map, borderColor);
                }
            }

            &::after {
                width: 15px;
                height: 15px;
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                right: 0;
                border-radius: 50%;
                border: 2px solid;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background-color: map-get($map, brandPantonGreen);
                        border-color: map-get($map, white);
                    }
                }
            }

            svg {
                width: 100%;
                padding: $indent-sm;
                border-radius: 50%;
                @each $theme, $map in $themes {
                    .#{$theme} & {
                        background-color: map-get($map, borderColor);
                        fill: map-get($map, brandPantonText);
                    }
                }
            }
        }
    }
}

.list-name {
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        width: 30px;
        height: 30px;
        transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
        background-size: contain;

        svg {
            width: 24px;
            height: 24px;
            transition: all 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
            @each $theme, $map in $themes {
                .#{$theme} & {
                    fill: map-get($map, brandPantonText);
                }
            }
        }
    }

    &__icon.turn {
        transform: rotate(-180deg);
    }
}
