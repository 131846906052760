@import '~assets/styles/utils/mixin';

.workingtime__filter__wrapper {
    .filter {
        &__selector {
            border-radius: 5px;
            padding: 14px;
            margin: 7px 14px 21px;
            background: $borderColor;

            & > button > svg {
                width: 12px;
                height: 12px;
                margin-right: 7px;
            }

            & > button {
                margin-top: 12px;
                cursor: pointer;
                color: #333;
            }
        }

        &__header {
            margin-bottom: 7px;
        }

        &__select__container {
            display: flex;
            align-items: center;

            .custom-input {
                width: 190px;
                margin-right: 14px;

                &__label {
                    margin-bottom: 0;
                }
            }
        }
    }
}
